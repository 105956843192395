/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from "react";

const historicalQuery = (coin = "bitcoin", date: string) =>
  fetch(
    `https://api.coingecko.com/api/v3/coins/${coin}/history?date=${date}`
  ).then((res) => res.json());

export type HistoricalPrice = {
  id: number;
  rate: number;
  date: Date;
};

const loadHistoricalData = async (
  coin = "bitcoin",
  day: Date = new Date(),
  currency = "usd"
) => {
  let year = day.getFullYear();
  const date = new Date(day);
  let id = 1;
  const today = new Date();
  const priceDates: HistoricalPrice[] = [];

  while (year) {
    date.setFullYear(year);
    const dateString = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    const data = await historicalQuery(coin, dateString);
    if (date > today) {
      console.log("skip day", date);
      year--;
      continue;
    }
    console.log(dateString, data);
    if (!data?.market_data?.current_price) {
      year = null;
    } else {
      priceDates.push({
        id,
        rate: data?.market_data?.current_price[currency],
        date: new Date(date),
      });
      year--;
      id++;
    }
  }
  return priceDates;
};

const useLoadHistorical = (coin = "bitcoin", day: Date, currency: string) => {
  const [historicalPrice, setHistoricalPrice] = useState<HistoricalPrice[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    setHistoricalPrice([]);
    loadHistoricalData(coin, day, currency)
      .then((data) => {
        setHistoricalPrice(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setError(true);
      });
  }, [coin, day, currency]);

  return { historicalPrice, loading, error };
};
export default useLoadHistorical;
