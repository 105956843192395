import React from "react";
import { createFormatter } from "../../../common/helpers/formatter";

type Props = {
  date: string;
  price: number;
  currency: 'usd' | 'eur' | string
};

const DailyPrice: React.FC<Props> = ({ date, price, currency }) => {
  const formatter = createFormatter(currency)
  const formattedPrice = formatter.format(price)

  return (
    <div>
      {date} - {formattedPrice}
    </div>
  );
};

export default DailyPrice;
