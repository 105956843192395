import React, { ComponentProps } from "react";
import DayPicker from 'react-day-picker';

import 'react-day-picker/lib/style.css';

const DatePicker: React.FC<ComponentProps<typeof DayPicker>> = ({...others}) => {
  return (
    <DayPicker className="bg-white rounded" {...others} />
  );
};

export default DatePicker;
