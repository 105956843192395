import React, { useState } from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import DailyPrice from "../../components/Modules/daily-price/daily-price";
import useLoadHistorical from "../../common/hooks/useLoadHistorical";
import Select from "../../components/UI-Elements/Form/Select";
import DatePicker from "../../components/UI-Elements/Form/DatePicker";
import { coins, currencies } from "../../common/helpers/config";
import Card from "../../components/UI-Elements/Card";
import DailyCart from "../../components/Modules/daily-price/daily-chart";
import Button from "../../components/UI-Elements/Button";
import Link from "../../components/UI-Elements/Navigation/Link";
import Title from "@components/UI-Elements/Typography/Title";

const Daily: React.FC = () => {
  const today = new Date();
  const description = `Mit diesem Tool kannst du die Entwicklung des Preises am jeweiligen Tag sehen. Zum Beispiel den Bitcoin Preis für heute den ${today.toLocaleDateString('de-DE')} über die letzten Jahre.`
  const [coin, setCoin] = useState("bitcoin");
  const [currency, setCurrency] = useState(currencies[0].value);
  const [date, setDate] = useState<Date>(today);
  const { historicalPrice, loading, error } = useLoadHistorical(coin, date, currency);

  const onDateChange = (day: Date) => { 
    setDate(day);
  };

  const onExample = (coin: string, day: string) => {
    setCoin(coin);
    setDate(new Date(`2022-${day}`));
  };

  return (
    <Layout>
      <SEO keywords={['preis am selben tag', 'bitcoin preis am gleichen tag']} title="Der Preis am gleichen Tag" description={description} />
      <Title>Der Preis am selben Tag</Title>
      <Card>
        <div className="md:flex justify-center">
          <div className="w-full pr-2 pb-2 md:pb-0 space-y-4">
            <div>
              <p>{description}</p>
              <p>Wähle dazu eine Kryptowährung, die zu verwendende Währung und einen beliebiges Datum aus.</p>
            </div>
            <div>
              <Title level={4}>Optionen</Title>
              <div className="grid md:grid-cols-2 gap-4">
                <div>
                  <p>Coin auswählen</p>
                  <Select options={coins} value={coin} onChange={(e) => setCoin(e.currentTarget.value)} />
                </div>
                <div>
                  <p>Währung</p>
                  <Select options={currencies} value={currency} onChange={(e) => setCurrency(e.currentTarget.value)} />
                </div>
              </div>
            </div>
            <div>
              <Title level={4}>Beispiele</Title>
              <div className="space-y-2 md:space-x-2">
                <Button onClick={() => onExample("bitcoin", "02-14")}>Bitcoin-Preis an Valentinstag</Button>
                <Button onClick={() => onExample("ethereum", "06-01")}>Ethereum am 1.Juni</Button>
              </div>
            </div>
          </div>
          <div>
            <Title level={4}>Datum auswählen</Title>
            <DatePicker selectedDays={[date]} onDayClick={onDateChange} />
          </div>
        </div>
      </Card>

      <Card title="Der Preis am jeweiligen Tag" className="mt-5">
        <div className="grid md:grid-cols-2 gap-4">
          <div>
            {loading && <p>Laden...</p>}
            {error && <p>Du hast das Rate-Limit der CoinGecko API erreicht. Warte eine Minute und probiere es dann erneut.</p>}
            {historicalPrice.map((value, index) => (
              <DailyPrice key={index} date={value.date.toLocaleDateString('de-DE')} price={value.rate} currency={currency} />
            ))}
          </div>
          <div>{!loading && historicalPrice.length > 0 && <DailyCart data={historicalPrice} currency={currency} />}</div>
        </div>
        <p className="pt-4">Quelle: <Link href="https://www.coingecko.com/" external>CoinGecko</Link></p>
      </Card>
    </Layout>
  );
};

export default Daily;
