import React from "react";
import { BarChart, Bar, ResponsiveContainer, XAxis, Tooltip, YAxis } from "recharts";

type Props = {
  data?: {
    name: string;
    value: number;
  }[];
  config?: {
    tooltipFormatter?: (value: number) => string;
  };
};

const SimpleBarChart: React.FC<Props> = ({ data, config: { tooltipFormatter } }) => {
  console.log(tooltipFormatter);

  return (
    <ResponsiveContainer width={"100%"} height="100%">
      <BarChart width={50} height={50} data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip formatter={tooltipFormatter} />
        <Bar dataKey="value" fill="#FF7A00" />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default SimpleBarChart;
