import React from "react";

export type Option = {
  value: string;
  label: string;
};

type Props = {
  options?: Option[];
} & React.SelectHTMLAttributes<HTMLSelectElement>;

const Select: React.FC<Props> = ({ options = [], ...others }) => {
  return (
    <select
      className="form-select appearance-none
                      block
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding bg-no-repeat
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
      {...others}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label || option.value}
        </option>
      ))}
    </select>
  );
};

export default Select;
