import React from "react";
import { formatCurrency } from "../../../common/helpers/formatter";
import { doubleDigitPrecise } from "../../../common/helpers/numbers";
import { HistoricalPrice } from "../../../common/hooks/useLoadHistorical";
import SimpleBarChart from "../../UI-Elements/Charts/SimpleBarChart";

type Props = {
  data?: HistoricalPrice[];
  currency: string;
};

const DailyCart: React.FC<Props> = ({ data = [], currency }) => {
  const chartData = data.sort((a, b) => a.date.getFullYear() - b.date.getFullYear()).map((item) => ({
    name: item.date.getFullYear().toString(),
    value: doubleDigitPrecise(item.rate)
  }));
  const tooltipFormatter = (value: number) => formatCurrency(value, currency, "de-DE")
  
  return (
    <SimpleBarChart data={chartData} config={{ tooltipFormatter }} />
  );
};

export default DailyCart;
